// @import '~@angular/material/theming';
@use '@angular/material/theming';
@import './app-theme';
@import './styles/general';



@include mat-core();

$primary: mat-palette($mat-grey);
$accent:  mat-palette($mat-red);

$theme: mat-light-theme($primary, $accent);
@include angular-material-theme($theme);
@include dwio-app-theme($theme);

mat-dialog-container.mat-mdc-dialog-container {
  // width: 270px;
  // to fix shift, used for snack bar container;
  position: relative;
  // top: calc(var(--safe-area-inset-bottom)/2 + 105px);

  padding:0;
  backdrop-filter: blur(3px);
  border-radius: 12px;
  color: white;
  .mat-mdc-dialog-surface {
    background-color: #1C1C1C;
    border-radius: 12px;
  }
}

// //Stupid workaround for safari 13.1
html {
  background: black;
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
}

body {
  background: black;
}

.cdk-drag-preview {
  opacity: 0.6;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
}

.cdk-drag-preview {
  // opacity: 0.5;
}


// .cdk-drag-animating {
//   transition: transform 50000ms cubic-bezier(0, 0, 0.2, 1);
// }

.page-transition-overlay {
  position: fixed;
  top: 0;
  // z-index: 10;
  background: #4A4D51;
  // height: 100vh;
  // width: 100vw;
  opacity: 0;
}

.cdk-global-overlay-wrapper {
  justify-content: flex-start;
  align-items: flex-end;
}

.cdk-overlay-pane {
  position: relative !important;
  bottom: calc(var(--safe-area-inset-bottom)/2 + 105px);
  // pointer-events: none;
  margin: 0 auto !important;
}

.cdk-overlay-pane.is-mobile {
  bottom: inherit;
}

// **
// WEB MENU, POPUP AND DIALOG !
// **

.vietnam {
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.youtube-dialog-container-link.cdk-overlay-pane {
  max-width: auto !important;
  bottom: auto !important;
}

.webmenu-overlay.cdk-overlay-pane{
  // position: relative !important;
  // bottom: 8px;
  // // pointer-events: none;
  margin: 0 !important;
  position: absolute !important;
  bottom: 20px;
  .mat-mdc-menu-panel {
    background-color: #1C1C1C;
    border: 1px solid #2B2B2E;
    border-radius: 24px;
    padding: 0px;
  }

  .mat-mdc-menu-content {
    padding: 4px 0 ;
  }

}
.webmenu-backdrop {
  background-color: #00000087;
}

.mat-menu-content {
  padding: 0 !important;
}


mat-progress-bar.mdc-linear-progress {
  border-radius: 3px;
  .mdc-linear-progress__bar-inner {
    border-radius: 3px;
  }
}

html.web app-confirmation-dialog {
  background: #1C1C1C;
  display: block;
  z-index: 5;
  position: relative;
  border-radius: 24px;
  padding: 20px 0;
}

html.web .mat-mdc-dialog-surface {
  background: transparent;
}

// MAIN
.webpopup-overlay {
  bottom: auto;

  mat-dialog-container .mdc-dialog__surface {
    top: 0;
    border-radius: 24px;
    border: 1px solid #2B2B2E;
    background: #171717;
    padding: 24px 29px;
    padding-top: 52px;
    width: auto;
    max-width: 440px;
  }

  &.web-account mat-dialog-container .mdc-dialog__surface {
    padding: 24px 40px;
  }

  .mat-dialog-actions{
    margin: 0;
    padding: 0;
  }

  .mdc-dialog__title::before {
    height: 0;
  }

  .mat-mdc-dialog-title {
    padding: 0 0px 9px;
    margin-bottom: 10px;
  }
  .mdc-dialog__content{
    padding: 0;
  }
}

// COMPACT
.compact-webpopup-overlay {
  bottom: auto;

  mat-dialog-container .mdc-dialog__surface {
    top: 0;
    border-radius: 24px;
    border: 1px solid #2B2B2E;
    background: #171717;
    padding: 24px 0px;
    width: auto;
  }
  .mat-dialog-actions{
    margin: 0;
    padding: 0;
  }

  .mdc-dialog__title::before {
    height: 0;
  }

  .mat-mdc-dialog-title {
    padding: 0 0px 9px;
    margin-bottom: 10px;
  }
  .mdc-dialog__content{
    padding: 0;
  }
}


// **
// END OF WEB STYLES
// **
.mdc-snackbar.mat-mdc-snack-bar-container {
  margin: 20px;
  // &.like-snackbar {
  //   bottom: calc(var(--safe-area-inset-bottom)/2 + 105px);
  //   position: relative;
  // }
}

.mat-mdc-snack-bar-container div.mdc-snackbar__surface  {

  background: white;
  .mdc-snackbar__label{
  color: black ;
  }
  &.like-snackbar {
  }

  .mat-mdc-snack-bar-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0.45px;
  }
  .mat-button.mat-button-base {
    color: black;
    font-weight: 500;
  }
}

.mat-mdc-snack-bar-container {
  &.text-center {
    text-align: center;
  }
}

.ispinner {
  position: relative;
  width: 20px;
  height: 20px; }
  .ispinner .ispinner-blade {
    position: absolute;
    left: 44.5%;
    top: 37%;
    width: 10%;
    height: 25%;
    border-radius: 50%/20%;
    -webkit-animation: ispinner-fade 1s linear infinite;
    animation: ispinner-fade 1s linear infinite;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
    .ispinner .ispinner-blade:nth-child(1) {
      -webkit-animation-delay: -1.66667s;
      animation-delay: -1.66667s;
      -webkit-transform: rotate(30deg) translate(0, -150%);
      transform: rotate(30deg) translate(0, -150%); }
    .ispinner .ispinner-blade:nth-child(2) {
      -webkit-animation-delay: -1.58333s;
      animation-delay: -1.58333s;
      -webkit-transform: rotate(60deg) translate(0, -150%);
      transform: rotate(60deg) translate(0, -150%); }
    .ispinner .ispinner-blade:nth-child(3) {
      -webkit-animation-delay: -1.5s;
      animation-delay: -1.5s;
      -webkit-transform: rotate(90deg) translate(0, -150%);
      transform: rotate(90deg) translate(0, -150%); }
    .ispinner .ispinner-blade:nth-child(4) {
      -webkit-animation-delay: -1.41667s;
      animation-delay: -1.41667s;
      -webkit-transform: rotate(120deg) translate(0, -150%);
      transform: rotate(120deg) translate(0, -150%); }
    .ispinner .ispinner-blade:nth-child(5) {
      -webkit-animation-delay: -1.33333s;
      animation-delay: -1.33333s;
      -webkit-transform: rotate(150deg) translate(0, -150%);
      transform: rotate(150deg) translate(0, -150%); }
    .ispinner .ispinner-blade:nth-child(6) {
      -webkit-animation-delay: -1.25s;
      animation-delay: -1.25s;
      -webkit-transform: rotate(180deg) translate(0, -150%);
      transform: rotate(180deg) translate(0, -150%); }
    .ispinner .ispinner-blade:nth-child(7) {
      -webkit-animation-delay: -1.16667s;
      animation-delay: -1.16667s;
      -webkit-transform: rotate(210deg) translate(0, -150%);
      transform: rotate(210deg) translate(0, -150%); }
    .ispinner .ispinner-blade:nth-child(8) {
      -webkit-animation-delay: -1.08333s;
      animation-delay: -1.08333s;
      -webkit-transform: rotate(240deg) translate(0, -150%);
      transform: rotate(240deg) translate(0, -150%); }
    .ispinner .ispinner-blade:nth-child(9) {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
      -webkit-transform: rotate(270deg) translate(0, -150%);
      transform: rotate(270deg) translate(0, -150%); }
    .ispinner .ispinner-blade:nth-child(10) {
      -webkit-animation-delay: -0.91667s;
      animation-delay: -0.91667s;
      -webkit-transform: rotate(300deg) translate(0, -150%);
      transform: rotate(300deg) translate(0, -150%); }
    .ispinner .ispinner-blade:nth-child(11) {
      -webkit-animation-delay: -0.83333s;
      animation-delay: -0.83333s;
      -webkit-transform: rotate(330deg) translate(0, -150%);
      transform: rotate(330deg) translate(0, -150%); }
    .ispinner .ispinner-blade:nth-child(12) {
      -webkit-animation-delay: -0.75s;
      animation-delay: -0.75s;
      -webkit-transform: rotate(360deg) translate(0, -150%);
      transform: rotate(360deg) translate(0, -150%); }
  .ispinner.animating .ispinner-blade {
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .ispinner.white .ispinner-blade {
    background-color: white; }
  .ispinner.gray .ispinner-blade {
    background-color: #8C8C8C; }
  .ispinner.large {
    width: 35px;
    height: 35px; }
    .ispinner.large .ispinner-blade {
      width: 8.57143%;
      height: 25.71429%;
      border-radius: 50%/16.67%; }

@-webkit-keyframes ispinner-fade {
  0% {
    opacity: 0.85; }
  50% {
    opacity: 0.25; }
  100% {
    opacity: 0.25; } }
@keyframes ispinner-fade {
  0% {
    opacity: 0.85; }
  50% {
    opacity: 0.25; }
  100% {
    opacity: 0.25; } }
