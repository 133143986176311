@import '../node_modules/@angular/material/theming';

@mixin dwio-app-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  body {
    color: #4A5960;
  }
}
