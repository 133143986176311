@import '../../node_modules/reset-css/sass/reset';

// :root {
//   color-scheme: light dark;
// }

html,
body {
  min-height: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
}

body {
  font-family: "Roboto","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
  font-size: 12px;
}

input[type=button] {
  font-family: "Roboto","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
  -moz-appearance: none;
  -webkit-appearance: none;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

/**
 * make youtube iframe adjust to parent block size
 */

 .uppercase {
   text-transform: uppercase;
 }

.youtube-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 30px;
  height: 0;
  width: 100%;
  overflow: hidden;
}

.youtube-container iframe,
.youtube-container object,
.youtube-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
